import { type Axios } from "axios";
import head from "lodash/head";
import maxBy from "lodash/maxBy";

import { type AddPayerCredentialsFormFields } from "../../components/CredentialsDashboard/Form/PayerCredentialsForm.zod";
import { ClaimStatus, ReviewStatus } from "../../status";
import { isDevMode } from "../../utils/utils";
import { QueryBuilder } from "./query";
/**
 * Calculates wieldyPaymentId and paymentId fields from wieldyId for each claim.
 * TODO: perhaps this should be moved to the backend on the message method of the
 * ClaimEnvelope
 * @param claims ClaimMessage[]
 * @returns ClaimMessage[]
 */

const paymentTypes = [
  null,
  "ACH",
  "CHECK",
  "CHK",
  "Check",
  "DIRECT DEPOSIT",
  "Direct Deposit",
  "EFT",
  "Electronic Funds Transfer",
  "WEB EFT",
  " ",
];

function withPaymentId(claims: ClaimMessage[]): ClaimMessage[] {
  return claims.map((pc) => {
    const wieldyPaymentId = pc.wieldyId.substring(
      0,
      pc.wieldyId.lastIndexOf("."),
    );
    const paymentId = wieldyPaymentId.split(".")[1];
    return {
      ...pc,
      wieldyPaymentId,
      paymentId,
    };
  });
}

// Returns an ISO 8601 unix epoch timestamp if text is null or empty.
function claimTime(claim: ClaimMessage): string {
  return claim.wieldyClaimDate ?? new Date(0).toISOString();
}

/**
 * For each patient in the provided array, filters all but the latest claim.
 * TODO: this is here until maxBy is properly implemented on the query engine.
 * @param patients PatientWithClaimMessage[]
 * @returns PatientWithClaimMessage[]
 */
function withLatestClaim(
  patients: PatientWithClaimMessage[],
): PatientWithClaimMessage[] {
  return patients.map((patient) => {
    const latestClaim = maxBy(patient.Claim, claimTime);
    return latestClaim ? { ...patient, Claim: [latestClaim] } : patient;
  });
}

function withPatientMessage(
  claims: ClaimWithProcedureMessage[],
  patients: PatientMessage[],
): ClaimWithProcedureAndPatientMessage[] {
  const patientsByWieldyId = patients.reduce<{ [key: string]: PatientMessage }>(
    (acc, curr) => {
      acc[curr.wieldyId] = curr;
      return acc;
    },
    {},
  );
  return claims.map((claim) => {
    const patient: PatientMessage[] = [];
    if (claim.wieldyPatientId && patientsByWieldyId[claim.wieldyPatientId]) {
      patient.push(patientsByWieldyId[claim.wieldyPatientId]);
    }
    return {
      ...claim,
      Patient: patient,
    };
  });
}

class QueryClient {
  private api: { getClient: () => Promise<Axios> };

  constructor(api: { getClient: () => Promise<Axios> }) {
    this.api = api;
  }

  async getPatients(): Promise<PatientWithClaimMessage[]> {
    const client = await this.api.getClient();
    const patientQuery = new QueryBuilder("Patient").build();
    const claimQuery = new QueryBuilder("Claim").build();
    /**
     * This is an interim solution until the query engine can query
     * joins more optimally. We change back to a join after this ticket is complete.
     * https://wieldy.atlassian.net/browse/WP-1360
     */
    const [patientResponse, claimResponse] = await Promise.all([
      client.post<PatientMessage[]>("/queries", patientQuery),
      client.post<ClaimMessage[]>("/queries", claimQuery),
    ]);
    const patientMap = patientResponse.data.reduce(
      (acc, curr) => {
        acc[curr.wieldyId] = { ...curr, ...{ Claim: [] } };
        return acc;
      },
      {} as { [key: string]: PatientWithClaimMessage },
    );
    claimResponse.data.forEach((claim) => {
      if (claim.wieldyPatientId && patientMap[claim.wieldyPatientId]) {
        patientMap[claim.wieldyPatientId].Claim.push(claim);
      }
    });
    return withLatestClaim(Object.values(patientMap));
  }

  async getPatientClaims(
    patientId: string,
  ): Promise<PatientWithClaimMessage | undefined> {
    const client = await this.api.getClient();
    const query = new QueryBuilder("Patient")
      .dice("wieldyId", [patientId])
      .join(new QueryBuilder("Claim").build())
      .build();

    const response = await client.post<PatientWithClaimMessage[]>(
      "queries",
      query,
    );
    const patient = head(response.data);
    if (!patient) {
      return undefined;
    }
    return {
      ...patient,
      Claim: withPaymentId(patient.Claim || []),
    };
  }

  async getPatientTransactions(
    patientId: string,
  ): Promise<PaymentTransactionMessage[] | undefined> {
    const client = await this.api.getClient();

    const response = await client.get<{ items: PaymentTransactionMessage[] }>(
      `/patient/${patientId}/transactions`,
    );
    return response.data?.items ?? [];
  }

  async getPatientPaymentPlans(
    patientId: string,
  ): Promise<PaymentPlanMessage[] | undefined> {
    const client = await this.api.getClient();

    const response = await client.get<{ paymentPlans: PaymentPlanMessage[] }>(
      `/patient/${patientId}/payment-plans`,
    );
    return response.data.paymentPlans;
  }

  async getPatientPaymentPlan(paymentPlanId: string): Promise<Blob> {
    const client = await this.api.getClient();

    const response = await client.get<Blob>(`/payment-plans/${paymentPlanId}`, {
      responseType: "blob",
      headers: {
        accept: "application/pdf",
      },
    });
    return response.data;
  }

  async cancelPatientPaymentPlans(paymentPlanId: string): Promise<void> {
    const client = await this.api.getClient();

    await client.post(`/payment-plans/${paymentPlanId}/cancellation`);
  }

  async sentPatientPaymentPlanViaSms(paymentPlanId: string): Promise<void> {
    const client = await this.api.getClient();

    await client.post(`/payment-plans/${paymentPlanId}/notify/sms`);
  }

  // /payment-plans/{payment_plan_id}/installments/{installment_id}/attempts
  async manuallyReattemptPayment(
    paymentPlanId: string,
    installmentId: string,
  ): Promise<void> {
    const client = await this.api.getClient();

    await client.post(
      `/payment-plans/${paymentPlanId}/installments/${installmentId}/attempts`,
    );
  }

  async getPatientPayments(
    patientId: string,
  ): Promise<PatientPaymentMessage[] | undefined> {
    const client = await this.api.getClient();

    const response = await client.get<PatientPaymentMessage[]>(
      `patient/${patientId}/payment-methods`,
    );
    return response.data;
  }

  async getPublicPatientPayments(
    hash: string,
  ): Promise<PatientPaymentMessage[] | undefined> {
    const client = await this.api.getClient();
    const response = await client.get<PatientPaymentMessage[]>(
      `public-patient/${hash}/payment-methods`,
    );
    return response.data;
  }

  async postPatientPayment(
    patientId: string,
    token: string,
  ): Promise<PatientPaymentMessage> {
    const client = await this.api.getClient();
    const response = await client.post<PatientPaymentMessage>(
      `patient/${patientId}/payment-methods`,
      {
        token,
      },
    );
    return response.data;
  }

  async postPublicPatientPayment(
    hash: string,
    token: string,
  ): Promise<PatientPaymentMessage> {
    const client = await this.api.getClient();
    const response = await client.post<PatientPaymentMessage>(
      `/public-patient/${hash}/payment-methods`,
      {
        token,
      },
    );
    return response.data;
  }

  async postPatientPaymentPlanUnsignedPdf(
    patientId: string,
    paymentPlanMessage: PaymentPlanMessage & { signatureDate: string },
  ): Promise<Blob> {
    const client = await this.api.getClient();
    const response = await client.post<Blob>(
      `patient/${patientId}/unsigned-payment-plan`,
      paymentPlanMessage,
      { responseType: "blob" },
    );
    return response.data;
  }

  async postPatientPaymentMethod(
    patientId: string,
    paymentPlanMessage: PaymentPlanMessage & {
      signatureDate: string;
      signature: string;
    },
  ): Promise<PaymentPlanMessage> {
    const client = await this.api.getClient();
    const response = await client.post<PaymentPlanMessage>(
      `patient/${patientId}/payment-plans`,
      paymentPlanMessage,
    );
    return response.data;
  }

  async setPatientPrimaryPaymentMethod(
    patientId: string,
    paymentMethodId: string,
  ): Promise<object> {
    const client = await this.api.getClient();
    const response = await client.patch<object>(`patient/${patientId}`, {
      primaryPaymentMethodId: paymentMethodId,
    });
    return response.data;
  }

  async setPublicPatientPrimaryPaymentMethod(
    hash: string,
    paymentMethodId: string,
  ): Promise<object> {
    const client = await this.api.getClient();
    const response = await client.patch<object>(`public-patient/${hash}`, {
      primaryPaymentMethodId: paymentMethodId,
    });
    return response.data;
  }

  async deletePaymentMethod(
    patientId: string,
    paymentMethodId: string,
  ): Promise<object> {
    const client = await this.api.getClient();
    const response = await client.delete<object>(
      `patient/${patientId}/payment-methods/${paymentMethodId}`,
    );
    return response.data;
  }

  async deletePublicPaymentMethod(
    hash: string,
    paymentMethodId: string,
  ): Promise<object> {
    const client = await this.api.getClient();
    const response = await client.delete<object>(
      `public-patient/${hash}/payment-methods/${paymentMethodId}`,
    );
    return response.data;
  }

  async getPayments() {
    const client = await this.api.getClient();

    const dice = [
      // TODO: WP-1881: Remove this once the query engine can handle dice by negation
      ["paymentType", paymentTypes],
      // We'll dice by paymentStatus till a longer term solution
      // https://wieldy.atlassian.net/browse/WP-1331
      ["paymentStatus", [null, "Cleared"]],
    ];
    if (!isDevMode()) {
      dice.push(["reviewStatus", [ReviewStatus.ACCEPTED]]);
    }
    const query = {
      type: "Payment",
      dice,
    };
    const response = await client.post<PaymentMessage[]>("/queries", query);
    return response.data;
  }

  async getPayment(paymentId: string): Promise<PaymentWithClaimMessage> {
    const client = await this.api.getClient();
    const query = {
      type: "Payment",
      dice: [
        ["wieldyId", [paymentId]],
        // TODO: WP-1881: Remove this once the query engine can handle dice by negation
        ["paymentType", paymentTypes],
      ],
      join: [{ type: "Claim" }],
    };
    const response = await client.post<PaymentWithClaimMessage[]>(
      "/queries",
      query,
    );
    return response.data[0];
  }

  async getClaimsProcedures(
    claimsIds: string[],
  ): Promise<ClaimWithProcedureMessage[]> {
    const client = await this.api.getClient();
    const baseClaimQuery = new QueryBuilder("Claim")
      .dice("claimType", ["CLAIM", null])
      .dice("claimStatus", [
        ClaimStatus[ClaimStatus.APPROVED],
        ClaimStatus[ClaimStatus.DENIED],
        null,
      ]);
    const claimQueryWithIds = claimsIds.length
      ? baseClaimQuery.dice("wieldyId", claimsIds)
      : baseClaimQuery;

    const claimQuery = isDevMode()
      ? claimQueryWithIds
      : claimQueryWithIds.dice("reviewStatus", [ReviewStatus.ACCEPTED]);

    const procedureQuery = new QueryBuilder("Procedure");
    /**
     * This is an interim solution until the query engine can query
     * joins more optimally. We change back to a join after this ticket is complete.
     * https://wieldy.atlassian.net/browse/WP-1360
     */
    const [claimResponse, procedureResponse] = await Promise.all([
      client.post<ClaimMessage[]>("/queries", claimQuery.build()),
      client.post<ProcedureMessage[]>("/queries", procedureQuery.build()),
    ]);

    const claimMap = claimResponse.data.reduce(
      (acc, curr) => {
        acc[curr.wieldyId] = { ...curr, ...{ Procedure: [] } };
        return acc;
      },
      {} as { [key: string]: ClaimWithProcedureMessage },
    );
    procedureResponse.data.forEach((procedure) => {
      const lastIndex = procedure.wieldyId.lastIndexOf(".");
      const claimKey = procedure.wieldyId.substring(0, lastIndex);
      if (claimMap[claimKey]) {
        claimMap[claimKey].Procedure.push(procedure);
      }
    });
    return Object.values(claimMap);
  }

  async getClaimsProceduresAndPatient(
    claimIds: string[],
  ): Promise<ClaimWithProcedureAndPatientMessage[]> {
    const claims = await this.getClaimsProcedures(claimIds);
    const query = new QueryBuilder("Patient");
    const client = await this.api.getClient();
    const response = await client.post<PatientMessage[]>(
      "/queries",
      query.build(),
    );
    return withPatientMessage(claims, response.data);
  }

  async getBankTransactions() {
    const client = await this.api.getClient();
    const query = new QueryBuilder("BankTransaction").build();
    const response = await client.post<BankTransactionMessage[]>(
      "/queries",
      query,
    );
    return response.data;
  }

  async postClaim(claimWork: ClaimWork): Promise<void> {
    const { search } = window.location;
    const client = await this.api.getClient();
    await client.post(`updates${search}`, claimWork);
  }

  async postToPMS(claimId: string): Promise<ClaimPostingStatus> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`pms/${claimId}${search}`, {
      claimId,
    });
    return response.data;
  }

  async uploadBankTransactions(practiceId: string, file: File): Promise<void> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const formData = new FormData();
    formData.append("file", file);
    await client.post(
      `practices/${practiceId}/reconciliation${search}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }

  async updateBankTransaction(
    transactionUpdate: BankTransactionUpdateMessage,
  ): Promise<void> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const { wieldyId, ...rest } = transactionUpdate;
    await client.post(`updates${search}`, {
      bankTransactionId: wieldyId,
      ...rest,
    });
  }

  async batchPostToPMS(): Promise<void> {
    const { search } = window.location;
    const client = await this.api.getClient();
    await client.post(`pms${search}`);
  }

  async postPatient(patient: CreatePatientMessage): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Patient",
      payload: patient,
    });
    return response.data.wieldyId;
  }

  async updatePatient(patient: PatientMessage): Promise<string> {
    const { search } = window.location;
    const client = await this.api.getClient();
    const response = await client.post(`updates${search}`, {
      type: "Patient",
      payload: patient,
    });
    return response.data.wieldyId;
  }

  async getPractices(): Promise<PracticeMessage[]> {
    const query = new QueryBuilder("Practice");
    const client = await this.api.getClient();
    const response = await client.post<PracticeMessage[]>(
      "/queries",
      query.build(),
    );
    return response.data;
  }

  async getCredentialsSupportedPayers(): Promise<
    CredentialsSupportedPayersMessage[] | undefined
    > {
    const client = await this.api.getClient();

    const response = await client.get<CredentialsSupportedPayersMessage[]>(
      `/credentials/supported-payers`,
    );
    return response.data ?? [];
  }

  async getUserCredentials(): Promise<UserCredentialsMessage[] | undefined> {
    const client = await this.api.getClient();
    const response =
      await client.get<UserCredentialsMessage[]>(`/credentials/`);
    return response.data ?? [];
  }

  async unmaskAttribute(
    wieldyId: string,
    name: string,
  ): Promise<Partial<UserCredentialsMessage>> {
    const client = await this.api.getClient();
    const response = await client.get(
      `/credentials/payers/${wieldyId}/unmask?name=${name}`,
    );
    return response.data;
  }

  async postCreateCredentials({
    type,
    payerId,
    practiceId,
    username,
    password,
    website,
    notes,
  }: {
    type: AddPayerCredentialsFormFields["type"];
    payerId: CredentialsSupportedPayersMessage["wieldyId"];
    practiceId: PracticeMessage["wieldyId"];
    username: UserCredentialsMessage["username"];
    password: UserCredentialsMessage["password"];
    website?: string;
    notes?: string;
  }): Promise<void> {
    const client = await this.api.getClient();

    await client.post(
      `/credentials/payers/${payerId}/practices/${practiceId}`,
      {
        type,
        username,
        password,
        website,
        notes,
      },
    );
  }

  async postOtherCredentials({
    type,
    practiceId,
    name,
    username,
    password,
    website,
    notes,
  }: {
    type: string;
    practiceId: PracticeMessage["wieldyId"];
    name: string;
    username: UserCredentialsMessage["username"];
    password: UserCredentialsMessage["password"];
    website?: string;
    notes?: string;
  }): Promise<void> {
    const client = await this.api.getClient();
    await client.post(`/credentials/other/practices/${practiceId}`, {
      type,
      name,
      username,
      password,
      website,
      notes,
    });
  }

  async deletePayerCredential(
    credentialId: UserCredentialsMessage["wieldyId"],
  ): Promise<void> {
    const client = await this.api.getClient();
    await client.delete(`/credentials/payers/${credentialId}`);
  }

  async deleteOtherCredential(
    credentialId: UserCredentialsMessage["wieldyId"],
  ): Promise<void> {
    const client = await this.api.getClient();
    await client.delete(`/credentials/other/${credentialId}`);
  }

  async updateCreateCredentials({
    credentialId,
    ...body
  }: {
    credentialId: UserCredentialsMessage["wieldyId"];
    username?: UserCredentialsMessage["username"];
    password?: UserCredentialsMessage["password"];
    website?: string;
    notes?: string;
  }): Promise<void> {
    const client = await this.api.getClient();
    await client.patch(`/credentials/payers/${credentialId}`, body);
  }

  async updateOtherCredentials({
    credentialId,
    ...body
  }: {
    credentialId: UserCredentialsMessage["wieldyId"];
    username?: UserCredentialsMessage["username"];
    password?: UserCredentialsMessage["password"];
    website?: string;
    notes?: string;
  }): Promise<void> {
    const client = await this.api.getClient();
    await client.patch(`/credentials/other/${credentialId}`, body);
  }
}

export default QueryClient;
