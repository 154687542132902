import "./PaymentsDetailsView.css";

import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { formatMoney } from "../../formatters";
import { isoToDate } from "../../utils/utils";
import ClaimsTable from "../ClaimsTable";
import Loading from "../Loading";
import PDFViewer from "../PDFViewer";
import { redactText } from "../redact";

function PaymentHeader(selectedPayment: PaymentMessage) {
  const { payerPaymentId, eopPaymentAmount, paymentDate, practiceDisplayName } =
    selectedPayment;
  return (
    <table>
      <thead>
        <tr>
          <td
            className="paymentHeaderCell"
            style={{ borderLeft: "0px", paddingLeft: "0px" }}
          >
            <Typography className="paymentHeaderTypography">
              <span className="paymentHeaderKey">Practice: </span>
              <span style={{ fontWeight: "600" }}>{practiceDisplayName}</span>
            </Typography>
          </td>
          <td className="paymentHeaderCell">
            <Typography className="paymentHeaderTypography" variant="subtitle1">
              <span className="paymentHeaderKey">Payment ID: </span>
              <span style={{ fontWeight: "600" }} data-sr-redact>
                {redactText(payerPaymentId)}
              </span>
            </Typography>
          </td>
          <td className="paymentHeaderCell">
            <Typography className="paymentHeaderTypography" variant="subtitle1">
              <span>Payment Amount: </span>
              <span style={{ fontWeight: "600" }} data-sr-redact>
                {formatMoney(eopPaymentAmount)}
              </span>
            </Typography>
          </td>
          <td className="paymentHeaderCell">
            <Typography className="paymentHeaderTypography" variant="subtitle1">
              <span className="paymentHeaderKey">Payment Date: </span>
              <span style={{ fontWeight: "600" }}>
                {paymentDate
                  ? isoToDate(paymentDate).toLocaleDateString()
                  : "-"}
              </span>
            </Typography>
          </td>
        </tr>
      </thead>
    </table>
  );
}

interface PaymentsDetailsViewProps {
  claims: ClaimWithProcedureAndPatientMessage[];
  updateClaim: (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void;
  postToPMS: (claim: ClaimWithProcedureAndPatientMessage) => void;
  payment: PaymentMessage | null;
  loadPayment: (paymentId: string) => Promise<void>;
  loadingClaims: boolean;
  reset: () => void;
}

export default function PaymentsDetailsView({
  claims,
  updateClaim,
  postToPMS,
  payment,
  loadPayment,
  loadingClaims,
  reset,
}: PaymentsDetailsViewProps) {
  const { paymentId } = useParams();
  useEffect(() => {
    if (paymentId) {
      loadPayment(paymentId);
    }
    return reset;
  }, []);

  if (!payment) {
    return (
      <div
        style={{
          display: "flex",
          height: "80vh",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      <h1 style={{ marginBottom: "12px" }}>{payment.payer}</h1>
      {PaymentHeader(payment)}
      <Grid
        container
        spacing={2}
        style={{
          marginTop: "27px",
          padding: "0px 2px",
        }}
      >
        <Grid item xs={12}>
          <ClaimsTable
            claims={claims}
            isLoading={loadingClaims}
            onClaimUpdate={updateClaim}
            onPostToPMS={postToPMS}
            enableClaimDetails
            initialSortingModel={[
              { field: "urgency", sort: "desc" },
              { field: "wieldyPatientName", sort: "asc" },
            ]}
            excludeByFieldNames={[
              "urgency",
              "sources",
              "payer",
              "paymentId",
              "practice",
              "wieldyClaimDate",
              "notes",
            ]}
            sortColumnOrder={[
              "availableSince",
              "claimStatus",
              "wieldyPatientName",
              "claimId",
              "claimPayerPays",
              "action",
              "postedState",
              "postedDateTime",
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <PDFViewer selectedPayment={payment} />
        </Grid>
      </Grid>
    </>
  );
}
