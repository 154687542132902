import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";

import { isDevMode } from "../../utils/utils";

export const DEFAULT_COLUMN_VISIBILITY: GridColumnVisibilityModel = {
  urgency: true,
  practice: true,
  claimId: true,
  claimPayerPays: true,
  wieldyPatientName: true,
  availableSince: true,
  wieldyClaimDate: true,
  action: true,
  postedState: true,
  postedDateTime: true,
  notes: true,
  sources: true,
  payer: true,
  paymentId: true,
  claimReceivedDate: false,
  claimProcessedDate: false,
  claimType: false,
  claimAdjustmentAmount: false,
  patientDob: false,
  providerId: false,
  wieldySubscriberName: false,
  subscriberId: false,
  relationshipToSubscriber: false,
  lifetimeBenefitUsed: false,
  claimGroupName: false,
  claimGroupNumber: false,
  patientPayerNetwork: false,
  claimStatus: true,
  bankMatchState: false,
  reviewStatus: isDevMode(),
};
